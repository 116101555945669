import React from "react";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormLabel,
  RadioGroup,
  Radio,
  Grid,
  FormControlLabel,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  EXCEL,
  PDF_CATALOGUE,
  PDF_PRICE_LSIT,
} from "../utils/DownloadFileTypeOptions";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";

const FormAltro = ({
  lista,
  expanded,
  handleChange,
  selectedFileType,
  setSelectedFileType,
  checkedMesaurementStates,
  setCheckedMesaurementStates,
  checkedCountMesaurement,
}) => {
  const { t } = useTranslation();
  const accordionId = "other";
  const theme = useTheme();

  const handleChangeFileType = (event) => {
    setSelectedFileType(event.target.value);
  };

  function handleOnChangeChecked(event) {
    const { name, checked } = event.target;
    setCheckedMesaurementStates((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  }

  function renderMeasurements() {
    if (lista.measurementOptions) {
      return lista.measurementOptions.map(({ key, label }) => {
        const isChecked = checkedMesaurementStates[key];

        return (
          <FormControlLabel
            key={key}
            label={label}
            control={
              <Checkbox
                id={`misureForm-${key}`}
                name={`${key}`}
                checked={isChecked}
                onChange={(event) => handleOnChangeChecked(event)}
              />
            }
          />
        );
      });
    } else {
      return {};
    }
  }
  function renderCapacities() {
    if (lista.capacityOptions) {
      return lista.capacityOptions.map(({ key, label }) => {
        return (
          <FormControlLabel
            key={key}
            label={label}
            control={<Checkbox id={`capacitaForm-${key}`} name={`${key}`} />}
          />
        );
      });
    } else {
      return {};
    }
  }

  return (
    <Accordion
      expanded={expanded === accordionId}
      onChange={handleChange(accordionId)}
      className={
        selectedFileType === "2" && checkedCountMesaurement === 0
          ? "accordionError"
          : ""
      }
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {selectedFileType === "2" && checkedCountMesaurement === 0 ? (
          <h4 style={{ color: theme.palette.error.main }}>
            {t("labels.other")}*
          </h4>
        ) : (
          <h4>{t("labels.other")}</h4>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={2} display="flex" justifyContent="center">
            <FormGroup md={{ offset: 1 }}>
              <FormLabel>
                <h4>{t("labels.productCategory")}</h4>
              </FormLabel>
              <RadioGroup name="group_product_category" defaultValue="0">
                <FormControlLabel
                  label="Website"
                  value="0"
                  control={<Radio />}
                />
                <FormControlLabel
                  label="BI (DWH)"
                  value="1"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormGroup>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="center">
            <FormGroup md={{ offset: 1 }}>
              <FormLabel>
                <h4>{t("labels.collection")}</h4>
              </FormLabel>
              <RadioGroup name="group_collection" defaultValue="0">
                <FormControlLabel
                  label="Website"
                  value="0"
                  control={<Radio />}
                />
                <FormControlLabel
                  label="BI (DWH)"
                  value="1"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormGroup>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="center">
            <FormGroup md={{ offset: 1 }}>
              <FormLabel>
                <h4>{t("labels.measureUnit")}</h4>
              </FormLabel>
              {renderMeasurements()}
              <Box sx={{ minWidth: "185px" }}>
                {selectedFileType === "2" && checkedCountMesaurement === 0 && (
                  <FormHelperText error>
                    {t("labels.errorForMesaurementSelection")}
                  </FormHelperText>
                )}
              </Box>
            </FormGroup>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="center">
            <FormGroup md={{ offset: 1 }}>
              <FormLabel>
                <h4>{t("labels.quantity")}</h4>
              </FormLabel>
              {renderCapacities()}
            </FormGroup>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="center">
            <FormGroup md={{ offset: 1 }}>
              <FormLabel>
                <h4>{t("labels.multipack")}</h4>
              </FormLabel>
              <RadioGroup name="group_multipack" defaultValue="1">
                <FormControlLabel
                  label={t("labels.web")}
                  value="0"
                  control={<Radio />}
                />
                <FormControlLabel
                  label={t("labels.notWeb")}
                  value="1"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormGroup>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="center">
            <FormGroup md={{ offset: 1 }}>
              <FormLabel>
                <h4>{t("labels.html")}</h4>
              </FormLabel>
              <RadioGroup name="group_html" defaultValue="1">
                <FormControlLabel
                  label={t("labels.html")}
                  value="0"
                  control={<Radio />}
                />
                <FormControlLabel
                  label={t("labels.plain")}
                  value="1"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormGroup>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="center">
            <FormGroup md={{ offset: 1 }}>
              <FormLabel>
                <h4>{t("labels.decor")}</h4>
              </FormLabel>
              <RadioGroup name="group_decoration" defaultValue="0">
                <FormControlLabel
                  label={t("labels.decor")}
                  value="0"
                  control={<Radio />}
                />
                <FormControlLabel
                  label={t("labels.white")}
                  value="1"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormGroup>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="center">
            <FormGroup md={{ offset: 1 }}>
              <FormLabel>
                <h4>{t("labels.duplicates")}</h4>
              </FormLabel>
              <RadioGroup name="group_duplicates" defaultValue="1">
                <FormControlLabel
                  label={t("labels.delete")}
                  value="0"
                  control={<Radio />}
                />
                <FormControlLabel
                  label={t("labels.allow")}
                  value="1"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormGroup>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="center">
            <FormGroup md={{ offset: 1 }}>
              <FormLabel>
                <h4>{t("labels.articleClass")}</h4>
              </FormLabel>
              <RadioGroup name="group_article_class" defaultValue="0">
                <FormControlLabel
                  label={t("labels.notInclude")}
                  value="0"
                  control={<Radio />}
                />
                <FormControlLabel
                  label={t("labels.include")}
                  value="1"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormGroup>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="center">
            <FormGroup md={{ offset: 1 }}>
              <FormLabel>
                <h4>{t("labels.summaryCells")}</h4>
              </FormLabel>
              <FormControlLabel
                label={t("labels.formattedDimensions")}
                control={<Checkbox id="formattedDimensions" defaultChecked />}
              />
              <FormControlLabel
                label={t("labels.productSummary")}
                control={<Checkbox id="productSummary" />}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="center">
            <FormGroup md={{ offset: 1 }}>
              <FormLabel>
                <h4>{t("labels.barcodeTitle")}</h4>
              </FormLabel>
              <FormControlLabel
                label={t("labels.eanHeader")}
                control={<Checkbox id="eanBarcode" />}
              />
              <FormControlLabel
                label={t("labels.upcHeader")}
                control={<Checkbox id="upcBarcode" />}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="center">
            <FormGroup md={{ offset: 1 }}>
              <FormLabel>
                <h4>{t("labels.fileTypeTitle")}</h4>
              </FormLabel>
              <RadioGroup
                name="group_fileType"
                value={selectedFileType}
                onChange={handleChangeFileType}
              >
                <FormControlLabel
                  label={t("labels.downloadExcelFile")}
                  value={EXCEL}
                  control={<Radio />}
                />
                <FormControlLabel
                  label={t("labels.downloadPdfCatalogueFile")}
                  value={PDF_CATALOGUE}
                  control={<Radio />}
                />
                <FormControlLabel
                  label={t("labels.downloadPdfPriceListFile")}
                  value={PDF_PRICE_LSIT}
                  control={<Radio />}
                />
              </RadioGroup>
            </FormGroup>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default FormAltro;
