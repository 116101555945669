import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Alert,
  CircularProgress,
  Avatar,
  colors,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import LinearProgress from "@mui/material/LinearProgress";
import StepButton from "@mui/material/StepButton";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useParams } from "react-router";
import i18next from "i18next";
import getProcessingSessionById from "../fetchers/GetProcessingSessionById";
import { useTranslation } from "react-i18next";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FullPageLoader from "../components/FullPageLoader";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { PageLayout } from "../components/PageLayout";
import CustomToaster from "../components/CustomToaster";
import relaunchStep from "../fetchers/RelaunchStep";
import ReplayIcon from "@mui/icons-material/Replay";
import { Steps } from "../models/Steps";

const StepsPage = () => {
  const [processingSession, setProcessingSession] = useState({});
  const [isCompletedWithError, setIsCompletedWithError] = useState(false);
  const [dataFetchStep, setDataFetchStep] = useState([]);

  const [fileSteps, setFileSteps] = useState([]);
  const [notifyFileSteps, setNotifyFileSteps] = useState([]);

  const [imagesSteps, setImagesStep] = useState([]);
  const [notifyImagesSteps, setNotifyImagesStep] = useState([]);
  const fetchDataStepIndex = 0;
  const fileStepIndex = 1;
  const imageStepIndex = 2;
  const endStepIndex = 3;
  const [activeStep, setActiveStep] = useState(fetchDataStepIndex);
  const [loading, setLoading] = useState(true);
  const [showToasterServerError, setShowToasterServerError] = useState(false);
  const [selectedStep, setSelectedStep] = useState(false);
  const [serverError, setServerError] = useState("");
  const { t } = useTranslation();
  const { processingSessionId } = useParams();
  const stepStyle = {
    ".MuiStepIcon-root.Mui-completed": { color: "green" },
  };

  useEffect(() => {
    var timerId = setInterval(() => updateState(), 5000);

    //Unmount
    return () => {
      clearInterval(timerId);
    };
  }, [selectedStep]);

  const updateState = () => {
    try {
      getProcessingSessionInfo();
    } catch (e) {
      console.log(e);
    }
  };

  const downloadFile = (document) => {
    window.open(document.downloadUrl, "_blank").focus();
  };

  const getProcessingSessionInfo = async () => {
    try {
      var processingSession = await getProcessingSessionById(
        processingSessionId
      );
      const dataFetchingStep = processingSession.processingSteps.filter(
        (step) => step.type === Steps.FetchData
      );
      const excelGeneratingStep = processingSession.processingSteps.filter(
        (step) => step.type === Steps.Excel
      );
      const pdfGeneratingStep = processingSession.processingSteps.filter(
        (step) => step.type === Steps.Pdf
      );
      const excelNotifyStep = processingSession.processingSteps.filter(
        (step) => step.type === Steps.NotifyExcel
      );
      const pdfNotifyStep = processingSession.processingSteps.filter(
        (step) => step.type === Steps.NotifyPdf
      );
      const imageZipGeneratingStep = processingSession.processingSteps.filter(
        (step) => step.type === Steps.ImageZip
      );
      const imageZipNotifyStep = processingSession.processingSteps.filter(
        (step) => step.type === Steps.NotifyImageZip
      );

      setDataFetchStep(dataFetchingStep);
      if (excelGeneratingStep.length > 0) {
        setFileSteps(excelGeneratingStep);
        setNotifyFileSteps(excelNotifyStep);
      } else {
        setFileSteps(pdfGeneratingStep);
        setNotifyFileSteps(pdfNotifyStep);
      }

      setImagesStep(imageZipGeneratingStep);
      setNotifyImagesStep(imageZipNotifyStep);
      setProcessingSession(processingSession);
      if (loading && processingSession) setLoading(false);

      if (isProcessCompleted(processingSession)) {
        return setActiveStep(endStepIndex);
      }
    } catch (e) {
      console.log(e);
      setServerError(`errors.${e.detail ?? "GenericError"}`);
      setShowToasterServerError(true);
    }
  };

  useEffect(() => {
    if (isFetchDataStepCompleted(dataFetchStep) && selectedStep === false) {
      setActiveStep(fileStepIndex);
    }
  }, [dataFetchStep]);

  useEffect(() => {
    if (areFileStepsCompleted(fileSteps, notifyFileSteps)) {
      setActiveStep(imageStepIndex);
    }
  }, [fileSteps]);

  const handleStepClick = (index) => {
    if (!isProcessCompleted(processingSession)) {
      setSelectedStep(true);
      setActiveStep(index);
    }
  };

  const relaunchSelectedStep = async (id, accessToken) => {
    await relaunchStep(id, accessToken, i18next.language);
    setLoading(false);
  };

  const handleRelaunchStep = async (id) => {
    setLoading(true);
    try {
      await relaunchSelectedStep(id);
    } catch (e) {
      setServerError(`errors.${e.detail ?? "GenericError"}`);
      setShowToasterServerError(true);
    }
    setLoading(false);
  };

  const isFetchDataStepCompleted = (dataFetchStep) =>
    dataFetchStep.length > 0 &&
    dataFetchStep.findIndex((x) => x.finishDate == null) === -1;

  const areFileStepsCompleted = (fileSteps, notifyFileSteps) =>
    fileSteps.length > 0 &&
    fileSteps.findIndex((x) => x.finishDate == null) === -1 &&
    notifyFileSteps.findIndex((x) => x.finishDate == null) === -1;

  const areImagesStepsCompleted = () =>
    imagesSteps.findIndex((x) => x.finishDate == null) === -1 &&
    imagesSteps.length > 0 &&
    notifyImagesSteps.findIndex((x) => x.finishDate == null) === -1;
  const isImageStepDisabled = () => imagesSteps.length === 0;

  const isFileStepDisabled = () => fileSteps.length === 0;

  const isFetchDataStepDisabled = () => dataFetchStep.length === 0;
  const isProcessCompleted = (processingSession) =>
    processingSession.finishDate != null;
  const isProcessInError = () =>
    processingSession.processingSteps.findIndex((x) => x.error != null) !== -1;
  const isFetchDataStepInError = () =>
    dataFetchStep.findIndex((x) => x.error != null) !== -1;
  const areImagesStepsInError = () =>
    imagesSteps.findIndex((x) => x.error != null) !== -1 ||
    notifyImagesSteps.findIndex((x) => x.error != null) !== -1;

  const areFileStepsInError = () =>
    fileSteps.findIndex((x) => x.error != null) !== -1 ||
    notifyFileSteps.findIndex((x) => x.error != null) !== -1;

  const isStepComplete = (step) =>
    step.finishDate != null && step.error == null;
  const isStepInError = (step) => step.finishDate != null && step.error != null;
  const stepHasFileToDownload = (step) =>
    step.linkedDocuments.findIndex((x) => x.downloadUrl != null) !== -1;

  const stepCanBeRelaunched = (step) => {
    return step.type !== Steps.FetchData.toString();
  };
  const getFirstFileToDownload = (step) =>
    step.linkedDocuments.find((x) => x.downloadUrl != null);
  const getPartLabel = (step) => `${step?.part ?? 0}/${step?.totalParts ?? 0}`;
  const mapStep = (step, runningLabel, completedLabel, errorLabel) => {
    if (isStepComplete(step))
      return (
        <Box
          flex={1}
          display="flex"
          gap={2}
          key={step.id}
          margin={2}
          width="60%"
        >
          <TaskAltIcon style={{ color: "green", width: 25, height: 25 }} />
          <Typography style={{ color: "green" }}>
            {t(`labels.${completedLabel}`).replace("###", getPartLabel(step))}
          </Typography>
          {stepHasFileToDownload(step) && (
            <IconButton
              onClick={() => downloadFile(getFirstFileToDownload(step))}
              style={{ color: "green", width: 25, height: 25 }}
            >
              <FileDownloadIcon />
            </IconButton>
          )}
        </Box>
      );
    else if (isStepInError(step)) {
      return (
        <Box
          flex={1}
          display="flex"
          gap={2}
          key={step.id}
          width="60%"
          margin={2}
        >
          <ReportProblemIcon style={{ color: "red", width: 25, height: 25 }} />
          <Typography style={{ color: "red" }} marginBottom={"2%"}>
            {t(`labels.${errorLabel}`).replace("###", getPartLabel(step)) +
              t(`${step.error}`)}
          </Typography>
          {stepCanBeRelaunched(step) && (
            <IconButton
              onClick={() => handleRelaunchStep(step.id)}
              style={{ color: "red", width: 25, height: 25 }}
            >
              <ReplayIcon />
            </IconButton>
          )}
        </Box>
      );
    } else
      return (
        <Box
          flex={1}
          display="flex"
          key={step.id}
          flexDirection={"row"}
          margin={2}
          justifyContent={"flex-start"}
          width="60%"
        >
          <Typography style={{ color: "#1976d2" }} marginBottom={"2%"} flex={1}>
            {t(`labels.${runningLabel}`).replace("###", getPartLabel(step))}
          </Typography>
          <Box flex={3} marginTop={"1%"}>
            <LinearProgress />
          </Box>
        </Box>
      );
  };

  const getStepsIcon = (isInError, isCompleted, isDisabled, stepIndex) => {
    if (isCompleted && !isInError) {
      <TaskAltIcon style={{ color: "green", width: 25, height: 25 }} />;
    } else if (!isCompleted && !isDisabled && stepIndex !== endStepIndex) {
      return <CircularProgress size={"25px"} />;
    } else if (isInError) {
      return (
        <ReportProblemIcon style={{ color: "red", width: 25, height: 25 }} />
      );
    } else if (isCompleted && isInError) {
      return (
        <ReportProblemIcon style={{ color: "red", width: 25, height: 25 }} />
      );
    } else {
      return (
        <Avatar
          sx={{
            bgcolor: colors.grey[500],
            width: 25,
            height: 25,
            fontSize: "0.75rem",
          }}
        >
          {stepIndex}
        </Avatar>
      );
    }
  };

  const getStepProps = (processSteps, notifySteps) => {
    const generationInError =
      processSteps.findIndex((x) => x.error != null) !== -1;
    const notifyInError = notifySteps
      ? notifySteps.findIndex((x) => x.error != null) !== -1
      : false;

    if (generationInError || notifyInError) {
      return {
        optional: (
          <Typography variant="caption" color="error">
            {t("labels.stepError")}
          </Typography>
        ),
        error: true,
      };
    }
  };

  const getDoneProps = (isInError) => {
    if (isInError) {
      return {
        optional: (
          <Typography variant="caption" color="error">
            {t("labels.stepError")}
          </Typography>
        ),
        error: isInError,
      };
    }
  };

  useEffect(() => {
    setIsCompletedWithError(
      areImagesStepsInError() ||
        isFetchDataStepInError() ||
        areFileStepsInError()
    );
  }, [
    areImagesStepsInError(),
    isFetchDataStepInError(),
    areFileStepsInError(),
  ]);

  const renderFetchDataStep = (
    <>
      {dataFetchStep.map((step) =>
        mapStep(step, "dataFetching", "dataFectched", "dataFetchingError")
      )}
    </>
  );

  const renderFileSteps = (
    <>
      {fileSteps.map((step) =>
        mapStep(step, "fileGenerating", "fileGenerated", "fileGenerationError")
      )}
      {notifyFileSteps.map((step) =>
        mapStep(step, "fileNotifying", "fileNotified", "notifyError")
      )}
    </>
  );

  const renderImagesSteps = (
    <>
      {imagesSteps.map((step) =>
        mapStep(
          step,
          "imageZipGenerating",
          "imageZipGenerated",
          "imageZipGenerationError"
        )
      )}
      {notifyImagesSteps.map((step) =>
        mapStep(
          step,
          "imageZipNotifying",
          "imageZipNotified",
          "imageZipNotifyError"
        )
      )}
    </>
  );

  return (
    <PageLayout>
      {loading ? (
        <FullPageLoader />
      ) : (
        <>
          <Box width={"94%"} p={"3%"}>
            {isProcessCompleted(processingSession) ? (
              <Alert
                severity={isProcessInError() ? "error" : "success"}
                style={{ whiteSpace: "pre-line" }}
              >
                {isProcessInError()
                  ? t("labels.ProcessInError")
                  : t("labels.ProcessCompleted")}
              </Alert>
            ) : (
              <Alert severity="info" style={{ whiteSpace: "pre-line" }}>
                {t("labels.InProgress")}
              </Alert>
            )}
          </Box>
          <Box width={"94%"} p={"3%"}>
            <Stepper activeStep={activeStep} alternativeLabel nonLinear>
              <Step
                key="data"
                completed={isFetchDataStepCompleted(dataFetchStep)}
                sx={stepStyle}
                disabled
              >
                <StepButton
                  color="inherit"
                  onClick={() => handleStepClick(0)}
                  icon={getStepsIcon(
                    isFetchDataStepInError(),
                    isFetchDataStepCompleted(dataFetchStep),
                    isFetchDataStepDisabled(),
                    fetchDataStepIndex
                  )}
                >
                  <StepLabel {...getStepProps(dataFetchStep)}>
                    {t("labels.fetchDataStepTitle")}
                  </StepLabel>
                </StepButton>
              </Step>

              <Step
                key="file"
                completed={areFileStepsCompleted(fileSteps, notifyFileSteps)}
                sx={stepStyle}
              >
                <StepButton
                  color="inherit"
                  onClick={() => handleStepClick(1)}
                  icon={getStepsIcon(
                    areFileStepsInError(),
                    areFileStepsCompleted(fileSteps, notifyFileSteps),
                    isFileStepDisabled(),
                    fileStepIndex
                  )}
                >
                  <StepLabel {...getStepProps(fileSteps, notifyFileSteps)}>
                    {t("labels.fileStepTitle")}
                  </StepLabel>
                </StepButton>
              </Step>

              <Step
                key="imageZip"
                completed={areImagesStepsCompleted()}
                disabled={isImageStepDisabled()}
                sx={stepStyle}
              >
                <StepButton
                  icon={getStepsIcon(
                    areImagesStepsInError(),
                    areImagesStepsCompleted(),
                    isImageStepDisabled(),
                    imageStepIndex
                  )}
                  color="inherit"
                  onClick={() => handleStepClick(2)}
                >
                  <StepLabel {...getStepProps(imagesSteps, notifyImagesSteps)}>
                    {t("labels.imagesStepTitle")}
                  </StepLabel>
                </StepButton>
              </Step>
              <Step
                key="done"
                completed={isProcessCompleted(processingSession)}
                sx={stepStyle}
                disabled
              >
                <StepButton
                  icon={getStepsIcon(
                    isCompletedWithError,
                    isProcessCompleted(processingSession),
                    isFetchDataStepDisabled() ||
                      isFileStepDisabled() ||
                      isImageStepDisabled(),
                    endStepIndex
                  )}
                  color="inherit"
                >
                  <StepLabel {...getDoneProps(isCompletedWithError)}>
                    {isCompletedWithError
                      ? t("labels.stepError")
                      : t("labels.doneStepTitle")}
                  </StepLabel>
                </StepButton>
              </Step>
            </Stepper>
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {isProcessCompleted(processingSession) ? (
                <>
                  {renderFetchDataStep}
                  {renderFileSteps}
                  {renderImagesSteps}
                </>
              ) : activeStep === fetchDataStepIndex ? (
                renderFetchDataStep
              ) : activeStep === fileStepIndex ? (
                <>
                  {renderFetchDataStep}
                  {renderFileSteps}
                </>
              ) : activeStep === imageStepIndex ? (
                <>
                  {renderFetchDataStep}
                  {renderFileSteps}
                  {renderImagesSteps}
                </>
              ) : (
                <></>
              )}
            </div>
          </Box>
        </>
      )}
      {showToasterServerError && (
        <CustomToaster
          showToaster={showToasterServerError}
          setShowToaster={setShowToasterServerError}
          message={t(serverError)}
          color="error"
        />
      )}
    </PageLayout>
  );
};

export default StepsPage;
