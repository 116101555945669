import React, { useEffect, useState } from "react";
import FormLingue from "./FormLingue";
import FormMercati from "./FormMercati";
import FormListini from "./FormListini";
import FormGestioneImmagini from "./FormGestioneImmagini";
import FormAltro from "./FormAltro";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Box, Chip } from "@mui/material";
import { ITALIAN_ISO_CODE } from "../utils/LanguagesOptions";

const Parameters = ({
  lista,
  inputLavorazione,
  handleInputLavorazione,
  selectedPriceList,
  setDisableButtonIfMandatoryOptionsAreNotValid,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState("");
  const [selectedWebLanguages, setSelectedWebLanguages] = useState([
    ITALIAN_ISO_CODE,
  ]);
  const [selectedAxLanguages, setSelectedAxLanguages] = useState([
    ITALIAN_ISO_CODE,
  ]);
  const [selectedFileType, setSelectedFileType] = useState("0");
  const [checkedPriceListStates, setCheckedPriceListStates] = useState(() => {
    return lista.listingOptions.reduce((acc, { key }) => {
      acc[key] = key == selectedPriceList;
      return acc;
    }, {});
  });
  const [disabledPriceListCheckboxes, setDisabledPriceListCheckboxes] =
    useState({});
  const [checkedCountListsPrice, setCheckedCountListsPrice] = useState(0);
  const [checkedMesaurementStates, setCheckedMesaurementStates] = useState({});
  const [checkedCountMesaurement, setCheckedCountMesaurement] = useState(0);

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    const checkedCount = Object.values(checkedPriceListStates).filter(
      (value) => value === true
    ).length;
    setCheckedCountListsPrice(checkedCount);

    if (selectedFileType === "2" && checkedCount === 1) {
      const newDisabledState = Object.keys(checkedPriceListStates).reduce(
        (acc, key) => {
          acc[key] = !checkedPriceListStates[key];
          return acc;
        },
        {}
      );
      setDisabledPriceListCheckboxes(newDisabledState);
    } else {
      setDisabledPriceListCheckboxes({});
    }
  }, [checkedPriceListStates, selectedFileType]);

  useEffect(() => {
    const checkedCount = Object.values(checkedMesaurementStates).filter(
      (value) => value === true
    ).length;
    setCheckedCountMesaurement(checkedCount);
  }, [checkedMesaurementStates, selectedFileType]);

  useEffect(() => {
    const shouldDisableButton =
      selectedAxLanguages.length === 0 ||
      selectedWebLanguages.length === 0 ||
      (selectedFileType === "2" && checkedCountListsPrice > 1) ||
      (selectedFileType === "2" && checkedCountMesaurement === 0);

    setDisableButtonIfMandatoryOptionsAreNotValid(shouldDisableButton);
  }, [
    selectedAxLanguages,
    selectedWebLanguages,
    selectedFileType,
    checkedCountListsPrice,
    checkedCountMesaurement,
  ]);

  return (
    <Grid container direction="column">
      <Grid item xs={12} marginBottom={3}>
        <h1>{t("labels.parameters")}</h1>
      </Grid>
      <Grid item xs={12} marginBottom={4} paddingLeft="10%">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-end",
            width: "80%",
            alignSelf: "center",
          }}
        >
          <Chip label="YYYY-MM-DD-hh-mm-ss" sx={{ flex: 1 }} />
          <TextField
            sx={{ flex: 5 }}
            id="inputLavorazioneForm"
            label={t("placeholders.insertJobName")}
            variant="standard"
            value={inputLavorazione}
            onChange={handleInputLavorazione}
          />
        </Box>
      </Grid>
      <Grid item xs={12} marginBottom={4}>
        <FormLingue
          lista={lista}
          handleChange={handleAccordionChange}
          expanded={expanded}
          selectedAxLanguages={selectedAxLanguages}
          setSelectedAxLanguages={setSelectedAxLanguages}
          selectedWebLanguages={selectedWebLanguages}
          setSelectedWebLanguages={setSelectedWebLanguages}
        />
        <FormListini
          lista={lista}
          handleChange={handleAccordionChange}
          expanded={expanded}
          selectedPriceList={selectedPriceList}
          disabledCheckboxes={disabledPriceListCheckboxes}
          checkedStates={checkedPriceListStates}
          setCheckedStates={setCheckedPriceListStates}
          checkedCountListsPrice={checkedCountListsPrice}
          selectedFileType={selectedFileType}
        />
        <FormMercati
          lista={lista}
          handleChange={handleAccordionChange}
          expanded={expanded}
        />
        <FormGestioneImmagini
          handleChange={handleAccordionChange}
          expanded={expanded}
        />
        <FormAltro
          lista={lista}
          handleChange={handleAccordionChange}
          expanded={expanded}
          setSelectedFileType={setSelectedFileType}
          selectedFileType={selectedFileType}
          checkedMesaurementStates={checkedMesaurementStates}
          setCheckedMesaurementStates={setCheckedMesaurementStates}
          checkedCountMesaurement={checkedCountMesaurement}
        />
      </Grid>
    </Grid>
  );
};

export default Parameters;
